const config = {
  "broker": "1",
  "name": "Spanish",
  "registrationBundle": "spanish",
  "defaultCountryName": "Mexico",
  "defaultCountryCode": "MX",
  "fxgeoip_code": "MX",
  languageCode: 'es',
  languageName:'Spanish',
  spokenLanguageCode:'es',
  direction:'ltr',
  footerDisclaimer: {
    'iforexone.mx': '792',
    'iforex.cl': '934'
  }
};

module.exports = config;